import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'

const FreeGuideDownload = () => {


   return (
      <Layout headerTransparent={false}>
         <SEO
            title={'Free Guide Download | Sell Your Home For Cash Sacramento'}
            description={'FREE Guide Below! If you need help any time during the process just give us a call or shoot us an email. We can walk you through your options.'}
            keywords={''}
         />


         <section className="py-16 section-stripe-bg bg-gray-50 ">
            <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
               <div className="w-full md:w-2/3 md:pr-4 prose">

                  <h1>Congrats! Download Your FREE Guide Below!
                  </h1>
                  <p>If you need help any time during the process just <a href="/contact-us/">give us a call or shoot us an email</a>. We can walk you through your options, look at your specific situation, and in many cases, we can buy your house at a fair price in as little as 7 days with no commissions or fees.</p>

                  <div className="flex flex-wrap">


                     <div className="mb-2  w-full md:w-1/2 mt-6 ">
                        <div className="md:mr-2 rounded-xl shadow  bg-white p-3">
                           <p className="font-semibold text-lg text-black">Download Your FREE "Pros, Cons, and Pitfalls" Guide Below</p>
                           <a href="/SellingtoARealEstateInvestor.pdf">Download</a>
                        </div>

                     </div>
                     <div className="mb-2 w-full md:w-1/2 mt-6">
                        <div className="md:ml-2 rounded-xl shadow  bg-white p-3">
                        <p className="font-semibold text-lg text-black">Download Your FREE Stop Foreclosure Guide Below</p>
                        <a href="/stop-foreclosure-guide.pdf">Download</a>
                        </div>
                     </div> 
                  </div> 
               </div> 
            </div>
         </section>
 

      </Layout>
   );
};


export default FreeGuideDownload;


 


